import React, { useEffect } from 'react'
import './Header.scss';
import { Dialog, Fade, IconButton, InputAdornment, Menu, MenuItem, OutlinedInput } from '@mui/material';
import { IUser } from '../../context/models';
import { useAppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { BLOB_ENDPOINT_MEDIA } from '../../services/config';
import PictureTag from '../PictureTag';

export interface IHeaderProps {
  user: IUser;
}

function Header() {

  const { brandList, masterData } = useAppContext();
  // console.log('useAppContext ~~ Header~~~~ brandList', JSON.stringify(brandList))
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);

  const [searchText, setSearchText] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMore = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickMheader = () => {
    setOpenMenu(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleSearch = () => {
    handleClose();
    if (searchText) {
      navigate('search?text=' + searchText);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(); // Call the search function
    }
  };

  const handleMoreAction = (path: string) => {
    navigate(path);  // Navigate to the "Brand" page
    setAnchorEl(null);
  };
  const handleNavigation = (path: string) => {
    navigate(path);  // Navigate to the "Brand" page
    setOpenMenu(false);
  };

  const { userName } = useAppContext()

  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');

    // Set the source of the Adobe script (replace with your actual Adobe script URL)
    script.src = masterData?.lOLMasterData.adobeAnalyticsScript || '';
    script.async = true; // Make sure it loads asynchronously
    script.onload = () => {
      console.log('Adobe script loaded successfully');
    };
    script.onerror = (err) => {
      console.error('Error loading Adobe script:', err);
    };

    // Append the script to the document head
    document.head.appendChild(script);

    // Cleanup the script when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array means it runs once when the component mounts

  const imgUrl = masterData ?
    BLOB_ENDPOINT_MEDIA + masterData?.lOLMasterData?.headerLogo[0]?.path.split('/').pop() || '' :
    '/images/forage-genetics.png';
  return (
    <>
      <div className="header">
        <div className="header-container">
          <img src={imgUrl} alt="Forage Genetics" className='logo' onClick={() => handleNavigation('/')} />
          <div className='brands'>
            {brandList?.filter(a => !a.isTrait)?.map((brand, index) => (
              <div key={index} className='brand' onClick={() => handleNavigation(brand.nodeAlias)} style={{ order: brand.sortOrder }}>

                {brand.headerLogo.length > 0 ?
                   <PictureTag fallbackSrc={BLOB_ENDPOINT_MEDIA + brand?.headerLogo[0]?.path.split('/').pop() || ''} alt={brand.title} webpSrc={brand?.webpHeaderLogo?.[0]?.path
                    ? BLOB_ENDPOINT_MEDIA + brand.webpHeaderLogo[0].path.split('/').pop()
                    : ''} className='icon' />: 
                  <span className='text'>{brand.title}</span>
                }
              </div>
            ))}
            <div className='brand' id="fade-button"
              aria-controls={openMore ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMore ? 'true' : undefined}
              onClick={handleClick}
              style={{ order: 3 }}>
              <span className='text'>Traits
                {openMore ? <i className={`fgi fgi-triangle-up`}></i> : <i className={`fgi fgi-triangle-down`}></i>}

              </span>
            </div>

            <IconButton aria-label="Search" className='search' size="small" disabled={open} onClick={handleClickOpen} style={{ order: 9999 }}>
              <i className={`fgi fgi-magnifying-glass`}></i>
            </IconButton>
          </div>
          <div className='user'>
            <div className='user-name'> {`Welcome ${userName}!`} </div>
            {/* <div className='user-logout'>Logout</div> */}
          </div>
          <Menu
            id="fade-menu"
            className='fade-menu'
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={openMore}
            onClose={handleMoreClose}
            TransitionComponent={Fade}
            data-testid="trait-fade-menu"
          >
            {brandList?.filter(a => a.isTrait).map((brand, index) => (
              <MenuItem key={index} onClick={() => handleMoreAction(brand.nodeAlias)}>
                {brand.headerLogo.length > 0 ?
                 <PictureTag fallbackSrc={BLOB_ENDPOINT_MEDIA + brand?.headerLogo[0]?.path.split('/').pop() || ''} alt={brand.title} webpSrc={brand?.webpHeaderLogo?.[0]?.path
                  ? BLOB_ENDPOINT_MEDIA + brand.webpHeaderLogo[0].path.split('/').pop()
                  : ''} className='icon' />:
                  <span className='text'>{brand.title}</span>
                }
              </MenuItem>
            ))}
          </Menu>
        </div>
        <div className="header-container-mobile">
          {openMenu ?
            (<IconButton aria-label="close" className='more' size="small" onClick={handleMenuClose}>
              <i className={`fgi fgi-close`}></i>

            </IconButton>
            ) :
            <IconButton aria-label="more" className='more' size="small" onClick={handleClickMheader}>
              <i className={`fgi fgi-menu`}></i>
            </IconButton>
          }


          <img src={imgUrl} alt="Forage Genetics" className='logo' onClick={() => handleNavigation('/')} />

          <IconButton aria-label="Search" className='search' size="small" disabled={open || openMenu} onClick={handleClickOpen}>
            <i className={`fgi fgi-magnifying-glass`}></i>
          </IconButton>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="search-dialog"
        disableEnforceFocus
        disableEscapeKeyDown
      >
        <OutlinedInput
          id="search-input"
          aria-label="Search Input"
          type="text"
          placeholder="Search"
          value={searchText}
          inputProps={{
            minLength: 3, // Enforces the minimum length
          }}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={handleKeyDown}
          className="search-dialog-input-container"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                className='search-input-action'
                aria-label="Search Action"
                onClick={handleSearch}
                edge="end"
              >
                <i className={`fgi fgi-magnifying-glass`}></i>
              </IconButton>
            </InputAdornment>
          }
        />
      </Dialog>
      <Dialog
        fullScreen
        open={openMenu}
        className='menu-dialog'
      >
        <div className='brands-mobile'>
          {brandList?.map((brand, index) => (
            <div key={index} className='brand-mobile' onClick={() => handleNavigation(brand.nodeAlias)}>
              {brand.headerLogo.length > 0 ?
                <PictureTag fallbackSrc={BLOB_ENDPOINT_MEDIA + brand?.headerLogo[0]?.path.split('/').pop() || ''} alt={brand.title} webpSrc={brand?.webpHeaderLogo?.[0]?.path
                  ? BLOB_ENDPOINT_MEDIA + brand.webpHeaderLogo[0].path.split('/').pop()
                  : ''} className='icon' />: 
                <span className='text'>{brand.title}</span>
              }
            </div>
          ))}
          {/* <Button className='logout-mobile' variant="contained">Logout User</Button> */}

        </div>
      </Dialog>
    </>
  );
}

export default Header;
