import React from "react";

export interface IPictureTagProps {
  webpSrc?: string;
  fallbackSrc: string;
  alt: string;
  className?: string;
}

function Picture({ webpSrc, fallbackSrc, alt, className }: IPictureTagProps) {
  return (
    <picture>
    {webpSrc && <source srcSet={webpSrc}/>}
    <img src={fallbackSrc} loading="lazy" className ={className} alt={alt}/>
  </picture>
  
  );
}

export default Picture;
